import { eventBus, busService } from '@/utility';
import store from '../store';

let ticketTimeouts = [5000, 7000, 10000, 18000, 30000, 50000]; // Cumulatively 2:00 min => 120 seconds
// let ticketTimeouts = [2000, 3000, 4000, 6000, 8000, 10000]; // For testing
let ticketChecks = [];
let ticketTimeout = null;

/**
 *
 * @param {Object} ticket
 * Sends event to check ticket eather by barcode or request uuid based on if the param ticket has a valid barcode
 */
const checkTicket = async (ticket) => {
  if (ticket.id && ticket.id !== '-') {
    eventBus.$emit('ticketCheckByBarcode', { ticket: ticket, sendTicketUpdate: true });
  } else {
    eventBus.$emit('ticketCheckByRequestId', ticket);
  }
};

/**
 * Checks if it should remove tickets from ticketChecks array and removes them
 */
const clearTickets = () => {
  let shouldClear = true;
  for (let i = 0; i < ticketChecks.length; i += 1) {
    if (ticketChecks[i].checkInProgress) shouldClear = false;
  }

  if (shouldClear) ticketChecks = ticketChecks.filter((ticket) => ticket.shouldBeChecked);
};

/**
 * Adds ticket to the array of tickets that should be checked if that ticket isn't already in the array.
 * @param {Object} ticket
 *
 */
const addTicketToBeChecked = (ticket) => {
  const { id, requestUuid } = ticket;
  const ticketObject = {
    id,
    requestUuid,
    timesChecked: 0,
    checkInProgress: false,
    shouldBeChecked: true,
  };
  if (
    !ticketChecks.some((ticket) => {
      return ticket.requestUuid === requestUuid || (ticket.id === id && id !== '-');
    })
  ) {
    ticketChecks.push(ticketObject);
  }
};
/**
 * Starts ticket checker
 * @param {Object} ticket
 */
const startTicketChecker = (ticket) => {
  clearTickets();
  addTicketToBeChecked(ticket);

  for (let i = 0; i < ticketChecks.length; i += 1) {
    if (!ticketChecks[i].checkInProgress && ticketChecks[i].shouldBeChecked) {
      if (ticketTimeouts[ticketChecks[i]?.timesChecked]) {
        ticketChecks[i].checkInProgress = true;
        ticketTimeout = setTimeout(() => {
          if (ticketChecks[i].shouldBeChecked) {
            checkTicket(ticket);
            ticketChecks[i].checkInProgress = false;
            ticketChecks[i].timesChecked++;
            startTicketChecker(ticket);
          }
        }, ticketTimeouts[ticketChecks[i].timesChecked]);
      } else {
        const message = {
          message: store.getters.getTranslation('network_error_auto_check_failed'),
          type: 'warning',
        };
        busService.sendMessage('Dialog.Show', message);
        ticketChecks[i].shouldBeChecked = false;
      }
    }
  }
};
/**
 * Stops ticket checker
 * @param {Object} ticket
 */
const stopTicketChecker = (ticket) => {
  if (!ticket) return;
  const { requestId, requestUuid } = ticket;
  const id = ticket.codes?.find((code) => code.type === 'barcode')?.id || ticket.id;
  for (let i = 0; i < ticketChecks.length; i += 1) {
    if (
      ticketChecks[i].requestUuid === requestId ||
      ticketChecks[i].requestUuid === requestUuid ||
      (ticketChecks[i].id === id && id !== '-')
    ) {
      clearTimeout(ticketTimeout);
      ticketChecks[i].shouldBeChecked = false;
      ticketChecks[i].checkInProgress = false;
    }
  }
};

export default { startTicketChecker, stopTicketChecker };
